.checkerQuestion {
    font-size: 24px;
    font-family: 'Whitney Semibold';
    max-width: 370px;
    margin: 30px auto 0 auto;
}

.selectTitle {
    max-width: 360px;
    margin: 0 auto 24px;
    text-align: left;
    font-size: 14px;
    color: rgb(208, 204, 204);
    font-family: 'SF Pro Regular';
}

.selectedTitle {
    font-family: 'Whitney Semibold';
}

.flexGrow {
    flex-grow: 1;
    width: 100%;
}

.fbAuto {
    flex-basis: auto;
}

.numberWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.numberInner {
    flex: 1;
}

.diseaseWrapper {
    padding: 14px 38px 14px 18px;
    border-radius: 15px;
    max-width: 340px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #F1F0F0;
    -webkit-box-shadow: 0 2px 9px 0 rgba(0,0,0,0.23);
    -moz-box-shadow: 0 2px 9px 0 rgba(0,0,0,0.23);
    box-shadow: 0 2px 9px 0 rgba(0,0,0,0.23);
}

.customCheckbox {
    width: 22px;
    height: 22px;
    border: 2px solid #EEEFF2;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
}

.customCheckboxActive {
    position: absolute;
    right: 18px;
    width: 9px;
    height: 17px;
    border-style: solid;
    border-width: 0 3px 3px 0;
    top: calc(50% - 11px);
    transform: translateY(-50%) rotate(45deg);
    transform-origin: bottom left;
}

.checkboxDisease {
    position: absolute;
    left: -9999px;
    opacity: 0;
}

.checkboxDisease:checked + .checkboxDisease:after {
    opacity: 1;
}

.diseaseName {
    font-size: 16px;
    line-height: 20px;
    color: #54565B;
    font-weight: 600;
}

.checkerResultText {
    font-size: 21px;
    font-family: 'Whitney Semibold';
    max-width: 305px;
    margin: 0 auto;
}


.sliderQuestion {
    max-width: 260px;
}

.rangeSliderWrapper {
    display: flex;
    margin-top: 70px;
    margin-bottom: 1.2rem;
    align-items: center;
}

.rangeSliderInner {
    flex-grow: 1;
}

.rangeSlider {
    -webkit-appearance: none;
    z-index: 1;
    width: 100%;
    height: 2px;
    border-radius: 0;
    background: #050505;
    outline: none;
    opacity: .4;
    margin-bottom: 30px;
}

.rangeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 78px;
    height: 38px;
    border-radius: 19px;
    background: #ffffff;
    box-shadow: 0 2px 5px 0 rgb(0, 0, 0);
    cursor: pointer;
}

.rangeSlider::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: 78px;
    height: 38px;
    border-radius: 19px;
    background: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0);
    cursor: pointer;
}

.rangeSliderValue {
    font-size: 30px;
    line-height: 60px;
    color: #171D49;
    margin: 0;
    text-align: center;
}

.rsArrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 27px;
    height: 27px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
    border-radius: 50%;
    cursor: pointer;
    margin-top: 3px;
    margin-left: 10px;
}
.rsArrow svg {
    margin-bottom: 2px;
}

.rsArrow img {
    max-width: 100%;
    width: 10px;
}

.rsArrow.rsArrowLeft {
    margin-left: 0;
    margin-right: 10px;
}
.rsArrowLeft svg {
    margin-right: 2px;
    margin-bottom: 2px;
}

.applyAllText {
    padding: 20px 0 0 20px;
    text-align: left;
    color: #8d8f94;
}

.diagnosticWrapper {
    min-width: 340px;
}

@media (min-width: 1024px) {
    .questionWrapper {
        max-width: 640px;
    }
}

@media (max-width: 350px) {
    .checker-result-text {
        max-width: none;
    }

    .diseaseWrapper {
        max-width: 225px;
    }
}
